import { useEffect } from 'react'

import { useRouter } from 'next/router'
import { FormattedMessage } from 'react-intl'
import useSWRImmutable from 'swr/immutable'

import { ACCIDENT } from '@avcan/constants/products'
import { FATAL_ACCIDENT_OPENED } from '@avcan/constants/products/mixpanel'
import * as Accidents from '@avcan/prismic/clients/accidents'
import { Entry, Metadata } from 'components/metadata'
import { Body, Close, DisplayOnMap, Header, Navbar } from 'components/page/drawer'
import Shim from 'components/Shim'
import { Loading, Muted } from 'components/text'
import { DateTime } from 'components/time'
import { NameMessages } from 'constants/products/names'
import * as Async from 'contexts/async'
import { useSendTrackEvent } from 'hooks/useSendTrackEvent'
import { useFlyTo, useSecondaryDrawer } from 'layouts/map/drawers/hooks'
import { StructuredText } from 'prismic/components/base'

export default function FatalAccident() {
    const { close, id } = useSecondaryDrawer()
    const { locale } = useRouter()
    const sendTrackEvent = useSendTrackEvent()

    const key = [id, locale]
    const value = useSWRImmutable(key, (id, locale) => Accidents.get(id, locale))
    const subject = <FormattedMessage {...NameMessages[ACCIDENT]} />

    useEffect(() => {
        if (id) {
            sendTrackEvent(FATAL_ACCIDENT_OPENED, { id })
        }
    }, [id, sendTrackEvent])

    return (
        <Async.Provider value={value}>
            <Navbar>
                <Close onClick={close} />
            </Navbar>
            <Header subject={subject}>
                <Async.Found>
                    <FatalAccidentHeader />
                </Async.Found>
            </Header>
            <Body>
                <Shim horizontal>
                    <Async.Pending>
                        <Loading>
                            <FormattedMessage
                                description="Layout drawers/FatalAccident"
                                defaultMessage="Loading fatal recreational accident..."
                            />
                        </Loading>
                    </Async.Pending>
                    <Async.Found>
                        <FatalAccidentBody />
                    </Async.Found>
                    <Async.NotFound>
                        <Muted>
                            <FormattedMessage
                                description="Layout drawers/FatalAccident"
                                defaultMessage="Fatal avalanche incident {id} not available anymore."
                                values={{ id }}
                            />
                        </Muted>
                    </Async.NotFound>
                </Shim>
            </Body>
        </Async.Provider>
    )
}

// Utils
function FatalAccidentHeader({ payload }) {
    const { title } = payload.data
    const flyTo = useFlyTo()
    function locate() {
        const { longitude, latitude } = payload.data.location

        flyTo([longitude, latitude])
    }

    return (
        <h1>
            <span>{title}</span>
            <DisplayOnMap onClick={locate} />
        </h1>
    )
}
function FatalAccidentBody({ payload }) {
    const { dateOfAccident, content } = payload.data
    const term = <FormattedMessage defaultMessage="Accident Date" description="Layout drawers/FatalAccident" />

    return (
        <>
            <Metadata>
                <Entry term={term}>
                    <DateTime value={dateOfAccident} />
                </Entry>
            </Metadata>
            <StructuredText value={content} />
        </>
    )
}
