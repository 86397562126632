import { useEffect } from 'react'

import Link from 'next/link'
import { useRouter } from 'next/router'
import { FormattedMessage } from 'react-intl'

import { iceClimbs } from '@avcan/constants/products/ice-climbs'
import { ICE_CLIMB_OPENED } from '@avcan/constants/products/mixpanel'
import { Body, Close, DisplayOnMap, Header, Navbar } from 'components/page/drawer'
import * as Texts from 'components/text'
import { useIceClimb } from 'hooks/useIceClimb'
import { useSendTrackEvent } from 'hooks/useSendTrackEvent'
import { useFlyTo, useSecondaryDrawer } from 'layouts/map/drawers/hooks'
import { SliceZone } from 'prismic/components/base'

export default function IceClimb() {
    const { locale } = useRouter()
    const { close, id } = useSecondaryDrawer()
    const sendTrackEvent = useSendTrackEvent()

    const subject = (
        <Link href="/resources/ice-climbing/atlas/">
            <FormattedMessage defaultMessage="Ice Climbing Atlas" description="Map drawer" />
        </Link>
    )
    const { data: document, isLoading } = useIceClimb(id, locale)

    useEffect(() => {
        if (id) {
            sendTrackEvent(ICE_CLIMB_OPENED, { id })
        }
    }, [id, sendTrackEvent])

    return (
        <>
            <Navbar>
                <Close onClick={close} />
            </Navbar>
            <Header subject={subject}>
                <h1>{isLoading ? <Texts.Loading /> : <Title id={id} title={document?.data?.title} />}</h1>
            </Header>
            <Body>
                <div style={style.body}>
                    {document && Array.isArray(document.data.content) && <SliceZone value={document.data.content} />}
                </div>
            </Body>
        </>
    )
}

const Title = ({ id, title }) => {
    const flyTo = useFlyTo()
    const location = iceClimbs.features.find(route => route.properties.id === id).geometry.coordinates

    const handleLocationClick = () => {
        flyTo(location)
    }

    return (
        <>
            <Link href={'/resources/ice-climbing/atlas/' + id}>{title}</Link>
            <DisplayOnMap onClick={handleLocationClick} />
        </>
    )
}

const style = {
    body: {
        padding: '0 1rem',
    },
}
